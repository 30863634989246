import { Avatar, Button, Divider, InputBase, Paper } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./puantaj.css";
import { makeStyles } from "@mui/styles";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";

import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../../components/datePickerInput";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
});

const PuantajScreen = () => {
  const classes = useStyles();
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const {
    ratingListFunc,
    ratingListNew,
    ratingListEducation,
    ratingListCompleted,
    puantajRecordFunc,
    setRatingListNew,
    setRatingListEducation,
    setRatingListCompleted,
    ratingRateFunc,
    ratingEducationNoteFunc,
  } = useContext(ManagementContext);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const audioRef = useRef(null);

  const changePlaybackRate = (rate) => {
    if (audioRef.current) {
      audioRef.current.audio.current.playbackRate = rate;
      setPlaybackRate(rate);
    }
  };

  useEffect(() => {
    if (endDate) {
      let data = {
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          state: "new",
          officeuser: "",
          task_type: "",
        },
      };
      let dataEdu = {
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          state: "education",
          officeuser: "",
          task_type: "",
        },
      };
      let dataComp = {
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          state: "completed",
          officeuser: "",
          task_type: "",
        },
      };
      ratingListFunc(data);
      ratingListFunc(dataEdu);
      ratingListFunc(dataComp);
    }
  }, [endDate]);

  const preparePayload = (array) => {
    return array
      .map((item) => {
        if (item.rating_type === "numeric") {
          return { id: item.id, value: item.numeric_rating };
        } else if (item.rating_type === "text") {
          return { id: item.id, value: item.text_rating };
        }
        return null;
      })
      .filter(Boolean);
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ margin: 3 }}>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          fixedHeight="35px"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          onChange={(update) => {
            setDateRange(update);
          }}
          customInput={
            <CustomDatePickerInput selectWidth={230} color={"#393737"} />
          }
          locale="tr"
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "33%",
            height: "80vh",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#ffb818",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "segoeuiB",
              fontSize: 13,
              padding: "5px 0px",
              color: "#fff",
              position: "relative",
            }}
          >
            YENİ
          </div>
          <div style={{ overflow: "auto" }}>
            {ratingListNew?.map((row, ind) => {
              return (
                <Paper
                  key={ind}
                  elevation={3}
                  style={{
                    width: "92%",
                    display: "flex",
                    padding: 5,
                    margin: "10px 10px",
                    backgroundColor: "#393737",
                    boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                    height: 300,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "32%",
                      alignItems: "center",
                      justifyContent: "space-around",
                      backgroundColor: "#474545",
                    }}
                  >
                    <Paper
                      style={{
                        backgroundColor: "#736868",
                        width: "-webkit-fill-available",
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontFamily: "monospace",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {row?.task_type?.name}
                      </div>
                    </Paper>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{ width: "40%", textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          alt="backoffice_user"
                          src={row?.officeuser?.picture}
                          sx={{ width: 64, height: 64, cursor: "pointer" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 14,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          {row?.officeuser?.full_name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            fontFamily: "monospace",
                            fontSize: 11,
                            color: "#fff",
                          }}
                        >
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {row?.cdr?.voice ? (
                        <div>
                          <AudioPlayer
                            ref={audioRef}
                            src={row?.cdr?.voice}
                            showJumpControls={false}
                            customAdditionalControls={[]}
                            style={{
                              padding: "2px 6px",
                              width: 170,
                              backgroundColor: "#474545",
                              boxShadow: "none",
                              color: "#fff",
                              marginTop: 2,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.0 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.0)}
                            >
                              1x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.25 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.25)}
                            >
                              1.25x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.5 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.5)}
                            >
                              1.5x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.75 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.75)}
                            >
                              1.75x
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              puantajRecordFunc({
                                record_id: row?.cdr?.id,
                                list: "new",
                              });
                            }}
                          >
                            Ses Dosyası
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "68%" }}>
                    <div style={{ height: "70%", overflow: "auto" }}>
                      {row?.features?.map((rowF, indF) => {
                        return (
                          <Paper
                            style={{
                              display: "flex",
                              color: "#fff",
                              padding: 2,
                              backgroundColor: "#403836",
                              width: "95%",
                              marginTop: 10,
                              fontFamily: "monospace",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontSize: 12,
                              }}
                            >
                              {rowF?.title}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "80%",
                                justifyContent: "space-between",
                                alignSelf: "center",
                                marginTop: 4,
                              }}
                            >
                              {Array.from({ length: 10 }, (_, i) => i + 1).map(
                                (rowArray) => {
                                  return (
                                    <div
                                      onClick={() => {
                                        const ratingListNewObj = Object.assign(
                                          [],
                                          JSON.parse(
                                            JSON.stringify({ ...ratingListNew })
                                          )
                                        );
                                        ratingListNewObj[ind].features[
                                          indF
                                        ].numeric_rating = rowArray;
                                        setRatingListNew(ratingListNewObj);
                                      }}
                                      style={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor:
                                          rowArray === rowF?.numeric_rating
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowArray === rowF?.numeric_rating
                                            ? "#f4f4f4"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {rowArray}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Paper>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        height: "30%",
                        alignItems: "center",
                      }}
                    >
                      <Paper
                        style={{
                          width: "75%",
                          display: "flex",
                          justifyContent: "space-between",
                          minHeight: 42,
                          backgroundColor: "#F0F0F0",
                          alignSelf: "center",
                          marginTop: 10,
                        }}
                      >
                        <InputBase
                          multiline
                          spellCheck={false}
                          style={{
                            width: "100%",
                            fontFamily: "segoeuiRg",
                            paddingLeft: 8,
                            fontSize: 14,
                          }}
                          rows={2}
                          placeholder={"Not ekle"}
                          className={classes.input}
                          value={row?.note ? row?.note : ""}
                          onChange={(e) => {
                            const ratingListNewObj = Object.assign(
                              [],
                              JSON.parse(JSON.stringify({ ...ratingListNew }))
                            );
                            ratingListNewObj[ind].note = e.target.value;
                            setRatingListNew(ratingListNewObj);
                          }}
                        />
                      </Paper>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20%",
                        }}
                      >
                        <div
                          style={{
                            height: "50%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 3,
                          }}
                        >
                          <GreenSwitch
                            onClick={() => {
                              const ratingListNewObj = Object.assign(
                                [],
                                JSON.parse(JSON.stringify({ ...ratingListNew }))
                              );
                              if (ratingListNewObj[ind].education) {
                                ratingListNewObj[ind].education =
                                  !row?.education;
                              } else {
                                ratingListNewObj[ind].education = true;
                              }
                              setRatingListNew(ratingListNewObj);
                            }}
                            checked={row?.education ? true : false}
                          />
                        </div>
                        <div
                          style={{
                            height: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "20%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              let data = {
                                rating_id: row?.id,
                                sendObj: {
                                  education: row?.education ? true : false,
                                  note: row?.note ? row?.note : "",
                                  features: preparePayload(row?.features),
                                },
                                startDate,
                                endDate,
                              };
                              ratingRateFunc(data);
                            }}
                          >
                            Kaydet
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              );
            })}
          </div>
        </div>
        <Divider
          orientation="vertical"
          sx={{
            backgroundColor: "gray",
            width: "1px",
            height: "80vh",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "33%",
            height: "80vh",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#6aa1ff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "segoeuiB",
              fontSize: 13,
              padding: "5px 0px",
              color: "#fff",
              position: "relative",
            }}
          >
            EĞİTİM{" "}
          </div>

          <div style={{ overflow: "auto" }}>
            {ratingListEducation?.map((row, ind) => {
              return (
                <Paper
                  key={ind}
                  elevation={3}
                  style={{
                    width: "92%",
                    display: "flex",
                    padding: 5,
                    margin: "10px 10px",
                    backgroundColor: "#393737",
                    boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                    height: 300,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "32%",
                      alignItems: "center",
                      justifyContent: "space-around",
                      backgroundColor: "#474545",
                    }}
                  >
                    <Paper
                      style={{
                        backgroundColor: "#736868",
                        width: "-webkit-fill-available",
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontFamily: "monospace",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {row?.task_type?.name}
                      </div>
                    </Paper>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{ width: "40%", textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          alt="backoffice_user"
                          src={row?.officeuser?.picture}
                          sx={{ width: 52, height: 52, cursor: "pointer" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 13,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                            textDecoration: "underline",
                          }}
                        >
                          Officeuser
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 14,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          {row?.officeuser?.full_name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            fontFamily: "monospace",
                            fontSize: 11,
                            color: "#fff",
                          }}
                        >
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      sx={{
                        backgroundColor: "gray",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{ width: "40%", textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          alt="backoffice_user"
                          src={row?.rated_by?.picture}
                          sx={{ width: 52, height: 52, cursor: "pointer" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 13,
                            fontWeight: 550,
                            color: "#fff",
                            textDecoration: "underline",
                            textAlign: "center",
                          }}
                        >
                          Değerlendiren
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 14,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          {row?.rated_by?.full_name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            fontFamily: "monospace",
                            fontSize: 11,
                            color: "#fff",
                          }}
                        >
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {row?.cdr?.voice ? (
                        <div>
                          <AudioPlayer
                            ref={audioRef}
                            src={row?.cdr?.voice}
                            showJumpControls={false}
                            customAdditionalControls={[]}
                            style={{
                              padding: "2px 6px",
                              width: 170,
                              backgroundColor: "#474545",
                              boxShadow: "none",
                              color: "#fff",
                              marginTop: 2,
                            }}
                          />

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.0 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.0)}
                            >
                              1x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.25 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.25)}
                            >
                              1.25x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.5 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.5)}
                            >
                              1.5x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.75 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.75)}
                            >
                              1.75x
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              puantajRecordFunc({
                                record_id: row?.cdr?.id,
                                list: "education",
                              });
                            }}
                          >
                            Ses Dosyası
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "68%" }}>
                    <div style={{ height: "70%", overflow: "auto" }}>
                      {row?.features?.map((rowF, indF) => {
                        return (
                          <Paper
                            style={{
                              display: "flex",
                              color: "#fff",
                              padding: 2,
                              backgroundColor: "#403836",
                              width: "95%",
                              marginTop: 10,
                              fontFamily: "monospace",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontSize: 12,
                              }}
                            >
                              {rowF?.title}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "80%",
                                justifyContent: "space-between",
                                alignSelf: "center",
                                marginTop: 4,
                              }}
                            >
                              {Array.from({ length: 10 }, (_, i) => i + 1).map(
                                (rowArray) => {
                                  return (
                                    <div
                                      style={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor:
                                          rowArray === rowF?.numeric_rating
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowArray === rowF?.numeric_rating
                                            ? "#f4f4f4"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "default",
                                      }}
                                    >
                                      {rowArray}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Paper>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        height: "30%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "75%",
                        }}
                      >
                        <Paper
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            minHeight: 35,
                            backgroundColor: "#F0F0F0",
                            alignSelf: "center",
                            marginBottom: 5,
                          }}
                        >
                          <InputBase
                            multiline
                            spellCheck={false}
                            style={{
                              width: "100%",
                              fontFamily: "segoeuiRg",
                              paddingLeft: 8,
                              fontSize: 14,
                            }}
                            disabled={true}
                            rows={2}
                            placeholder={"Not ekle"}
                            className={classes.input}
                            value={row?.note}
                          />
                        </Paper>
                        <Paper
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            minHeight: 35,
                            backgroundColor: "#F0F0F0",
                            alignSelf: "center",
                          }}
                        >
                          <InputBase
                            multiline
                            spellCheck={false}
                            style={{
                              width: "100%",
                              fontFamily: "segoeuiRg",
                              paddingLeft: 8,
                              fontSize: 14,
                            }}
                            rows={2}
                            placeholder={"Not ekle"}
                            className={classes.input}
                            value={
                              row?.education_note ? row?.education_note : ""
                            }
                            onChange={(e) => {
                              const ratingListEducationObj = Object.assign(
                                [],
                                JSON.parse(
                                  JSON.stringify({ ...ratingListEducation })
                                )
                              );
                              ratingListEducationObj[ind].education_note =
                                e.target.value;
                              setRatingListEducation(ratingListEducationObj);
                            }}
                          />
                        </Paper>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20%",
                        }}
                      >
                        <div
                          style={{
                            height: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "20%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              let data = {
                                rating_id: row?.id,
                                sendObj: {
                                  education_note: row?.education_note
                                    ? row?.education_note
                                    : "",
                                },
                                startDate,
                                endDate,
                              };
                              ratingEducationNoteFunc(data);
                            }}
                          >
                            Kaydet
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>
              );
            })}
          </div>
        </div>
        <Divider
          orientation="vertical"
          sx={{
            backgroundColor: "gray",
            width: "1px",
            height: "80vh",
          }}
        />{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "33%",
            height: "80vh",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "green",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "segoeuiB",
              fontSize: 13,
              padding: "5px 0px",
              color: "#fff",
              position: "relative",
            }}
          >
            TAMAMLANAN
          </div>
          <div style={{ overflow: "auto" }}>
            {ratingListCompleted?.map((row, ind) => {
              return (
                <Paper
                  key={ind}
                  elevation={3}
                  style={{
                    width: "92%",
                    display: "flex",
                    padding: 5,
                    margin: "10px 10px",
                    backgroundColor: "#393737",
                    boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                    height: 300,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "32%",
                      alignItems: "center",
                      justifyContent: "space-around",
                      backgroundColor: "#474545",
                    }}
                  >
                    <Paper
                      style={{
                        backgroundColor: "#736868",
                        width: "-webkit-fill-available",
                        color: "#fff",
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontFamily: "monospace",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {row?.task_type?.name}
                      </div>
                    </Paper>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{ width: "40%", textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          alt="backoffice_user"
                          src={row?.officeuser?.picture}
                          sx={{ width: 52, height: 52, cursor: "pointer" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 13,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                            textDecoration: "underline",
                          }}
                        >
                          Officeuser
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 14,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          {row?.officeuser?.full_name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            fontFamily: "monospace",
                            fontSize: 11,
                            color: "#fff",
                          }}
                        >
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      sx={{
                        backgroundColor: "gray",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{ width: "40%", textAlign: "-webkit-center" }}
                      >
                        <Avatar
                          alt="backoffice_user"
                          src={row?.rated_by?.picture}
                          sx={{ width: 52, height: 52, cursor: "pointer" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 13,
                            fontWeight: 550,
                            color: "#fff",
                            textDecoration: "underline",
                            textAlign: "center",
                          }}
                        >
                          Değerlendiren
                        </div>
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 14,
                            fontWeight: 550,
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          {row?.rated_by?.full_name}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            fontFamily: "monospace",
                            fontSize: 11,
                            color: "#fff",
                          }}
                        >
                          {moment(row?.created_at).format("DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {row?.cdr?.voice ? (
                        <div>
                          <AudioPlayer
                            ref={audioRef}
                            src={row?.cdr?.voice}
                            showJumpControls={false}
                            customAdditionalControls={[]}
                            style={{
                              padding: "2px 6px",
                              width: 170,
                              backgroundColor: "#474545",
                              boxShadow: "none",
                              color: "#fff",
                              marginTop: 2,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.0 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.0)}
                            >
                              1x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.25 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.25)}
                            >
                              1.25x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.5 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.5)}
                            >
                              1.5x
                            </div>
                            <div
                              style={{
                                backgroundColor:
                                  playbackRate === 1.75 ? "green" : "gray",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                                marginTop: 1,
                                borderRadius: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => changePlaybackRate(1.75)}
                            >
                              1.75x
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              puantajRecordFunc({
                                record_id: row?.cdr?.id,
                                list: "completed",
                              });
                            }}
                          >
                            Ses Dosyası
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "68%" }}>
                    <div style={{ height: "70%", overflow: "auto" }}>
                      {row?.features?.map((rowF, indF) => {
                        return (
                          <Paper
                            style={{
                              display: "flex",
                              color: "#fff",
                              padding: 2,
                              backgroundColor: "#403836",
                              width: "95%",
                              marginTop: 10,
                              fontFamily: "monospace",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontSize: 12,
                              }}
                            >
                              {rowF?.title}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "80%",
                                justifyContent: "space-between",
                                alignSelf: "center",
                                marginTop: 4,
                              }}
                            >
                              {Array.from({ length: 10 }, (_, i) => i + 1).map(
                                (rowArray) => {
                                  return (
                                    <div
                                      style={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor:
                                          rowArray === rowF?.numeric_rating
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowArray === rowF?.numeric_rating
                                            ? "#f4f4f4"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "default",
                                      }}
                                    >
                                      {rowArray}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Paper>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        height: "30%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "75%",
                        }}
                      >
                        <Paper
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            minHeight: 35,
                            backgroundColor: "#F0F0F0",
                            alignSelf: "center",
                            marginBottom: 5,
                          }}
                        >
                          <InputBase
                            multiline
                            spellCheck={false}
                            style={{
                              width: "100%",
                              fontFamily: "segoeuiRg",
                              paddingLeft: 8,
                              fontSize: 14,
                            }}
                            rows={2}
                            placeholder={"Not ekle"}
                            disabled={true}
                            className={classes.input}
                            value={
                              row?.education_note ? row?.education_note : "-"
                            }
                          />
                        </Paper>
                        <Paper
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            minHeight: 35,
                            backgroundColor: "#F0F0F0",
                            alignSelf: "center",
                          }}
                        >
                          <InputBase
                            multiline
                            spellCheck={false}
                            style={{
                              width: "100%",
                              fontFamily: "segoeuiRg",
                              paddingLeft: 8,
                              fontSize: 14,
                            }}
                            disabled={true}
                            rows={2}
                            placeholder={"Not ekle"}
                            className={classes.input}
                            value={row?.note}
                          />
                        </Paper>
                      </div>
                      {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "20%",
                      }}
                    >
                      <div
                        style={{
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 3,
                        }}
                      >
                        <GreenSwitch
                          onClick={() => {
                            const ratingListCompletedObj = Object.assign(
                              [],
                              JSON.parse(
                                JSON.stringify({ ...ratingListCompleted })
                              )
                            );
                            if (ratingListCompletedObj[ind].education) {
                              ratingListCompletedObj[ind].education =
                                !row?.education;
                            } else {
                              ratingListCompletedObj[ind].education = true;
                            }
                            setRatingListCompleted(ratingListCompletedObj);
                          }}
                          checked={row?.education ? true : false}
                        />
                      </div>
                      <div
                        style={{
                          height: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#f53b62",
                            color: "#fff",
                            fontFamily: "segoeuiB",
                            width: "20%",
                            display: "flex",
                            justifyContent: "center",
                            height: 20,
                          }}
                          size="small"
                          onClick={() => {
                            let data = {
                              rating_id: row?.id,
                              sendObj: {
                                education: row?.education ? true : false, 
                                note: row?.note ? row?.note : "",
                                features: preparePayload(row?.features),
                              },
                            };
                            ratingRateFunc(data);
                          }}
                        >
                          Kaydet
                        </Button>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </Paper>
              );
            })}
          </div>
        </div>
        <Divider
          orientation="vertical"
          sx={{
            backgroundColor: "gray",
            width: "1px",
            height: "80vh",
          }}
        />
      </div>
    </div>
  );
};

export default PuantajScreen;
